import React from 'react'
import './styles/_index.scss'
import { Col, Container, Row } from 'react-bootstrap'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const StaticDesc = (props) => {
    return (
        <div className='static-desc-wrapper'>
            <Container>
                <Row>
                    <Col xl={7}>
                        <h1>{props.title}</h1>
                        <div className='divider-line'></div>
                        {props.content && props.content?.content && <div className='static-desc-content'><ContentModule Content={props.content?.content?.data.content} /></div>}
                    </Col>
                    <Col xl={5} className="d-xl-block d-none"></Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaticDesc