import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import LayoutTwo from "../components/layoutTwo";
import Seo from "../components/seo"
import "../styles/inc/_sitemap.scss";
import StaticDesc from "../components/StaticDesc/StaticDesc";

const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const SitemapPage = () => {
  const data = useStaticQuery(graphql`
        query {
            allStrapiBurgerMenu(
                filter: {publish: {eq: true}}
                sort: {fields: sort, order: ASC}
              ) {
                edges {
                  node {
                    ...BurgerMenuFragment
                  }
                }
            }
        }
    `);

  const menus = data.allStrapiBurgerMenu.edges;

  return (
    <LayoutTwo customClass="sitemap" popularSearch={"static-detail"}>
      <div className="layout-padding-top"></div>
      <div className="grey-bg">
        <StaticDesc
          title="Sitemap"
        />
        <Container>
          <div className="sitemap-page">
            <ul className="sitemap-main-list">
              {menus && menus.map(({ node }, i) => {
                return (
                  <React.Fragment key={i}>
                    {node.sub_menu.length === 0 &&
                      <li className="li-main">
                        <CTALink {...node} />
                      </li>
                    }
                    {node.sub_menu.length > 0 &&
                      <li className="li-main">
                        <CTALink {...node} />
                        <ul className="sub-menu sitemap-sub-menu-list">
                          {node.sub_menu && node.sub_menu.map((item, i) => (
                            <li key={i} className='sub-li-list'>
                              <CTALink {...item} />

                              {item?.child && item.child?.length > 0 &&
                                <ul className="li-main sub-menu sitemap-sub-menu-list">
                                  {item?.child && item.child?.length > 0 && item.child.map((item, i) => (
                                    <li key={i} className='sub-li-list'>
                                      <CTALink {...item} />
                                    </li>
                                  ))}
                                </ul>}
                            </li>
                          ))}
                        </ul>

                      </li>
                    }
                  </React.Fragment>
                )
              }
              )}
            </ul>
          </div>
        </Container>
      </div>
    </LayoutTwo>

  )
}

export const Head = () => <Seo title="Sitemap" description={`Discover the comprehensive sitemap of ${process.env.GATSBY_SITE_NAME} website, featuring our range of property services, career opportunities, and contact information.`} />

export default SitemapPage
